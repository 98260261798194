<template>
  <v-dialog
    :value="value"
    scrollable
    persistent :overlay="true"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title primary-title class="font-weight-bold red--text text--darken-2">
        {{ $t('Error') }}
      </v-card-title>
      <v-card-text>
        <ul>
          <li v-for="(message, index) in messages" :key="'message-' + index">
            {{ $t(message) }}
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" dark color="#7f1416">{{ $t('Ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Error-Message',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    close () {
      return this.$emit('input', false)
    }
  }
}
</script>