// Pluging to encode filters to base64
const queryString = require('query-string')
const base64url = require('base64url')

// Create a custom plugin with interceptors
const Plugin = {
  install (Vue) {
    const { $axios } = Vue
    // Intercept all axios requests
    $axios.interceptors.request.use(async config => {
      let { url, query } = queryString.parseUrl(config.url)
      if (!Object.keys(query).length) return config
      if ('filter' in query) query.filter = base64url(query.filter)
      config.url = queryString.stringifyUrl({ url, query })
      return config
    })
  }
}

export default Plugin
