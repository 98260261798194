<template>
  <v-col cols="12" md="4">
    <router-link style="text-decoration: none;" :to="to">
      <v-card class="mx-auto" style="cursor: pointer;" height="135px">
        <v-card-text class="text-center">
          <div><v-icon size="64" class="mb-2" color="#7f1416">{{ icon }}</v-icon></div>
          <div style="font-size: 150%; color:#7f1416;">{{ name }}</div>
        </v-card-text>
      </v-card>
    </router-link>
  </v-col>
</template>

<script>
// Component declaration
export default {
  name: 'HomeCardView',
  props: {
    icon: {
      type: String,
      default: 'mdi-checkbox-blank-outline'
    },
    to: {
      type: String,
      default: '/'
    },
    name: {
      type: String,
      default: 'No data'
    }
  }
}
</script>