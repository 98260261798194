import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

// Full list of routes available in the app
// "meta" is used to define the HTML page title tag and to restict page access 
// by roles or by devices

const routes = [
  {
    // Main Page with custom links
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { title: 'SIDAR' },
  },
  {
    // Login page
    path: '/login',
    name: 'Login page',
    component: () => import('../views/Login.vue'),
    meta: { title: 'Login - SIDAR' }
  },
  {
    // The page to manage user accounts
    path: '/users',
    name: 'Manage Users',
    component: () => import('../views/Users.vue'),
    meta: { title: 'Users - SIDAR' }
  },
  {
    // The page to manage organizations
    path: '/organizations',
    name: 'Manage Organizations',
    component: () => import('../views/Organizations.vue'),
    meta: { title: 'Organizations - SIDAR', roles: ['Admin', 'Staff members'] },
  },
  {
    // The page with the list of addresses
    path: '/addresses',
    name: 'Address Management',
    component: () => import('../views/Addresses.vue'),
    meta: { title: 'Address Management - SIDAR' }
  },
  {
    // The page of an address with all assessments, activities, custom data and photos
    path: '/address/:_id',
    name: 'Address Review',
    component: () => import('../views/Address.vue'),
    meta: { title: 'Address - SIDAR' }
  },
  {
    // The page to manage the fields of the custom data
    path: '/metadata',
    name: 'Metadata Settings',
    component: () => import('../views/Metadata.vue'),
    meta: { title: 'Metadata - SIDAR', roles: ['Admin', 'Staff members', 'Partner organization manager'] }
  },
  {
    // The page to import data
    path: '/import',
    name: 'Import',
    component: () => import('../views/Import.vue'),
    meta: {
      title: 'Import data - SIDAR',
      roles: ['Admin', 'Staff members', 'Partner organization manager'],
      disableMobile: true
    }
  },
  {
    // User self-management page
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue'),
    meta: { title: 'User - SIDAR' }
  },
  {
    // The page with the user guide
    path: '/guide',
    name: 'Guide',
    component: () => import('../views/Guide.vue'),
    meta: {
      title: 'User guide - SIDAR'
    }
  },
  {
    // The page with information about the application
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: 'About - SIDAR'
    }
  },
  {
    // The page with updates
    path: '/updates',
    name: 'Updates',
    component: () => import('../views/Updates.vue'),
    meta: {
      title: 'Updates - SIDAR'
    }
  },
  {
    // The test page
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
    meta: {
      title: 'About - SIDAR'
    }
  },
  {
    // The page will allow users to book training
    path: '/training',
    name: 'Training',
    component: () => import('../views/Training.vue'),
    meta: {
      title: 'Training - SIDAR'
    }
  },
  
  {
    // The page with the user guide
    path: '/report',
    name: 'Report',
    component: () => import('../views/Report.vue'),
    meta: {
      title: 'Reporting - SIDAR'
    }
  },

  // Special path for handling offline mode
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      next('/')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior () { return { x: 0, y: 0 } }
})

// Adding meta tags and title to routes
router.beforeEach((to, from, next) => {

  // We use setTimeout here to ensure that this block will be executed after all other functions in the queue are completed
  setTimeout(() => {
    // Check if user is allowed to see the page
    let user = router.app?.$store?.state?.user
    // If the user is not authorized, then we take him back to the login page
    if (!user.name && to.path !== '/login') return next('/login')
    // If the user does not have permissions to access the page, then we take him back to the main page
    let role = user.role
    if (!role && to.path !== '/login') return next('/login')
    if (to.meta?.roles && !to.meta.roles.includes(role) && from.path !== '/') return next('/')
    // Check if the page is accessible from mobile
    if (router.app?.$vuetify.breakpoint.mobile && to.meta.disableMobile && from.path !== '/') return next('/')
  }, 0)

  // Set proper metatags for HTM page
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))
  if (!nearestWithMeta) return next()
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')
    Object.keys(tagDef).forEach(key => { tag.setAttribute(key, tagDef[key]) })
    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')
    return tag
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag))
  next()
})

export default router
