// Axios instance to handle authorization
import axios from 'axios'

// Here we define the URL to auth users
const VUE_APP_STATIC_API_URL = process.env.VUE_APP_STATIC_API_URL || 'http://localhost:3001/api/v1'

// List of paths to ignore
const ignorePath = [
  '/auth/recover',
  '/auth/change-password',
  '/auth/reset',
  '/auth/refresh',
  '/auth/login',
  '/auth/submit-sms'
]

// We expect that the request will be sent and receives credentials
const axiosAuth = axios.create({
  baseURL: VUE_APP_STATIC_API_URL,
  withCredentials: true
})
  
// Library to decode JWT token
import jwt_decode from 'jwt-decode'

// Library to login and logout in other browser tabs
import { BroadcastChannel } from 'broadcast-channel'
const channel = new BroadcastChannel('unhcr_sidar')

/* TOKEN MANAGEMENT */

// Global token and wait
let token

// Function to ask about the existing token
let askedForToken = false

// Custom function to extract and decode JWT from the header
const checkToken = () => {
  // Extract the authorization header from headers
  if (!token) return false
  // Check if token is expired
  const decoded = jwt_decode(token)
  if (!decoded.iat || !decoded.exp) return false
  // Refresh token in a one minute before expiration
  return decoded.exp - new Date().getTime() > 0
}

channel.onmessage = ({ command, message }) => {
  // Send the token if it is valid
  if (command === 'askToken' && checkToken(token)) {
    return channel.postMessage({ command: 'respondToken', message: token })
  }
  // If this a response to the request for the token
  if (command === 'respondToken') {
    // We don't want to sent the token muliple times
    if (!askedForToken) return false
    return window.dispatchEvent(new CustomEvent('setToken', { detail: message }))
  }
  // Set token if it is a general message
  if (command === 'setToken') {
    return window.dispatchEvent(new CustomEvent('setToken', { detail: message }))
  }
}

// Function to wait for the token
const waitForToken = () => {
  return new Promise((resolve) => {
    window.addEventListener('setToken', ({ detail }) => {
      askedForToken = false
      token = detail
      window.dispatchEvent(new CustomEvent('tokenEvent'))
      resolve()
    }, { once: true })
  })
}

// Functions to exchange tokens
const getToken = async () => {
  // We should check if other tabs respond
  await new Promise(resolve => setTimeout(resolve, 100))
  // Check authorization
  let tokenIsValid = checkToken(token)
  if (tokenIsValid) return true
  // Get a new token if necessary
  let result = await axiosAuth.get('/auth/refresh').catch(error => ({ error }))
  if (result.error) {
    window.dispatchEvent(new CustomEvent('setToken', { detail: '' }))
    return channel.postMessage({ command: 'setToken', message: '' })
  }
  let newtoken = result.data.accessToken
  window.dispatchEvent(new CustomEvent('setToken', { detail: newtoken }))
  return channel.postMessage({ command: 'setToken', message: newtoken })
}

const askToken = async () => {
  if (askedForToken) return true
  // If we have a token, there is no need to ask anything
  if (checkToken(token)) return true
  // Notify the tab that we're waiting for the token
  askedForToken = true
  // We ask other tabs if they have a valid token
  channel.postMessage({ command: 'askToken' })
  // If there are no valid tokens, we'll get the token on our own
  getToken()
}

/* VUE PLUGIN */

// Create a custom plugin with interceptors
const Plugin = {
  async install (Vue, { store, router }) {
    // Part to handle axios authorization
    const { $axios } = Vue
    // Make the token available outside for other services requiring auth (web-sockets)
    Vue.prototype.$auth = Vue.observable({ accessToken: undefined })
    window.addEventListener('tokenEvent', () => {
      if (!token) {
        store.dispatch('setUser', {})
        router.push('/login?tokenExpired')
      }
      Vue.prototype.$auth.accessToken = token
      $axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null
      axiosAuth.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null
    })
    // Intercept all axios requests
    $axios.interceptors.request.use(async config => {
      if (config.offline) return config
      // We do not need to react if user is trying login or get refresh token
      if (ignorePath.includes(config.url)) return config
      if (!token) askToken()
      if (askedForToken) await waitForToken()
      if (!token) return config
      config.headers.common.Authorization = `Bearer ${token}`
      return config
    })

    // Set broadcast to authorize in all tabs
    Vue.prototype.$broadcastToken = (newtoken) => {
      window.dispatchEvent(new CustomEvent('setToken', { detail: newtoken }))
      channel.postMessage({ command: 'setToken', message: newtoken })
    }
  }
}

export default Plugin
