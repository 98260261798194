import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import offline from './plugins/offline'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'leaflet'
window.Buffer = window.Buffer || require('buffer').Buffer

// Custom plugins
import vuetify from './plugins/vuetify'
import { translit, hasCyrillic } from './plugins/transliteration'
import Auth from './plugins/auth'
import getEncode from './plugins/getEncode'
import i18n from './i18n'

// Global components
import errorMessage from './components/ErrorMessage'

Vue.config.productionTip = false

// Add custom transliteration service
Vue.prototype.$translit = translit
Vue.prototype.$hasCyrillic = hasCyrillic

// Add axios
const VUE_APP_STATIC_API_URL = process.env.VUE_APP_STATIC_API_URL || 'http://localhost:3001/api/v1'
axios.defaults.baseURL = VUE_APP_STATIC_API_URL
axios.defaults.withCredentials = true

Vue.use(VueAxios, { $axios: axios })
Vue.use(Auth, { store, router })
Vue.use(offline)
Vue.use(getEncode)
Vue.component('errorMessage', errorMessage)

new Vue({
  components: {
    errorMessage
  },
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
