import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { translit, hasCyrillic } from './plugins/transliteration'
import uk from './locales/uk'
import enDict from './locales/en'

Vue.use(VueI18n)

const enProxy = {
  get (target, prop) {
    // The localization plugin is trying to work with all keys in the object and we want to avoid messing with metafields 
    if (['__ob__', '__v_isRef', '__v_skip'].includes(prop)) return target[prop]
    if (typeof prop === 'symbol') return target[prop]
    // If we need to translate the names of regions, then we simply take the Ukrainian name and transliterate it
    // Example: 'UA0502021006' => 'Білашки' => 'Bilashky'
    if (!target[prop] && prop.match(/^UA\d/)) return translit(uk[prop])
    // Returns the initial value, since our core dictionary values are in English
    if (!target[prop] && !hasCyrillic(prop)) return prop
    // Returns the updated English value if we want to represent string in English other way
    return target[prop]
  }
}

const en = new Proxy(enDict, enProxy)
const messages = { en, uk }

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages
})
