<template>
  <div class="about pa-2">
    <v-row class="mb-0 mt-0">
      <v-col cols="12" md="8" offset-md="2" class="mb-0 mt-0 pb-0 pt-0">
        <div class="text-h3 font-weight-bold text-center mb-4">{{ $t('Welcome to SIDAR') }}</div>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="8" offset-md="2">
        <v-row>
          <custom-link 
            icon="mdi-file-sign" 
            to="/addresses" 
            :name="$t('Manage addresses')"
          ></custom-link>
          <custom-link 
            v-show="$network.online && role === 'Admin' || !developmentMode" 
            icon="mdi-chart-box-outline" 
            to="/report" 
            :name="$t('Reports')"
          ></custom-link>
          <custom-link
            v-show="$network.online && ['Admin', 'Partner Admin', 'Partner organization manager', 'Local authorities', 'Shelter cluster IM'].includes(role)"
            class="hidden-xs-only"
            :name="$t('Import data')"
            icon="mdi-import"
            to="/import"
          ></custom-link>
          <custom-link
            v-show="['Admin', 'Staff member'].includes(role)"
            :name="$t('Organizations')"
            icon="mdi-domain"
            to="/organizations"
          ></custom-link>
          <custom-link
            v-show="$network.online && ['Admin',  'Partner organization manager', 'Oblast Administration', 'Local authorities'].includes(role)"
            :name="$t('Custom data settings')"
            icon="mdi-script-text-outline"
            to="/metadata"
          ></custom-link>
          <custom-link 
            v-show="$network.online" 
            icon="mdi-account-multiple-outline" 
            to="/users" 
            :name="$t('Users')"
          ></custom-link>
          <!--<custom-link v-show="role === 'Admin' || !developmentMode" icon="mdi-help-box-outline" to="/guide" :name="$t('User guide')"></custom-link>-->
          <!--<custom-link v-show="role === 'Admin' || !developmentMode" icon="mdi-information-box-outline" to="/about" :name="$t('About')"></custom-link>
          <custom-link v-show="role === 'Admin' || !developmentMode" icon="mdi-information-box-outline" to="/about" :name="$t('CC`s')"></custom-link>
            -->
          </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import customLink from '@/components/HomeViewCard'

export default {
  name: 'Home',
  beforeMount () {
    this.$store.dispatch('setBreadcrumbs', [
      { text: 'Home', disabled: false, href: '/' },
    ])
  },
  mounted () {
    // Initialize the process of syncing after the authorization
    this.$network.online = false
    setTimeout(() => { this.$network.online = true }, 0)
  },
  components: { customLink },
  computed: {
    role () { return this.$store?.getters?.user?.role },
    developmentMode () {
      return process.env.VUE_APP_DEVELOPMENT_MODE === 'yes'
    }
  }
}
</script>
